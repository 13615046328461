#maincontent > .not-found-container,
#maincontent > .error-page-container {
  max-width: 1400px;
  margin: 20px auto; }
  #maincontent > .not-found-container h1,
  #maincontent > .error-page-container h1 {
    color: #e20026;
    margin-bottom: 10px;
    font-size: 40px;
    text-align: center;
    font-family: "mukta-bold", sans-serif; }
  #maincontent > .not-found-container p,
  #maincontent > .error-page-container p {
    color: #333;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    text-align: center;
    font-family: "mukta-regular", sans-serif; }
    #maincontent > .not-found-container p.uppercase,
    #maincontent > .error-page-container p.uppercase {
      text-transform: uppercase; }
    #maincontent > .not-found-container p:last-of-type,
    #maincontent > .error-page-container p:last-of-type {
      margin: 10px 0; }
  #maincontent > .not-found-container img,
  #maincontent > .error-page-container img {
    width: 100%; }
  #maincontent > .not-found-container .buttons-container,
  #maincontent > .error-page-container .buttons-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    #maincontent > .not-found-container .buttons-container a,
    #maincontent > .error-page-container .buttons-container a {
      margin: 10px 2px;
      border: 1px solid #dfdfde;
      background-color: #dfdfde;
      width: 80%;
      max-width: 300px;
      height: 35px;
      text-align: center;
      color: #000;
      font-size: 14px;
      text-transform: lowercase;
      font-family: "mukta-bold", sans-serif; }
      #maincontent > .not-found-container .buttons-container a::first-letter,
      #maincontent > .error-page-container .buttons-container a::first-letter {
        text-transform: uppercase; }
      #maincontent > .not-found-container .buttons-container a:hover,
      #maincontent > .error-page-container .buttons-container a:hover {
        background-color: #fff;
        text-decoration: none; }

#maincontent > .error-page-container .text-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto; }

#maincontent > .error-page-container img {
  display: block;
  width: 90%;
  max-width: 500px;
  margin: 0 auto 20px; }

@media screen and (min-width: 768px) {
  #maincontent > .not-found-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    #maincontent > .not-found-container img {
      width: auto; }
    #maincontent > .not-found-container .buttons-container {
      width: 100%;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
  #maincontent > .error-page-container .text-container {
    width: 100%; }
  #maincontent > .error-page-container .buttons-container {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; } }
